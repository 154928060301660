import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingCeMarketingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Marcatura CE'

  const description =
    'La <b>MARCATURA CE</b> denomina un insieme di pratiche obbligatorie per tutti i prodotti per i quali esiste una direttiva comunitaria, che include anche l’applicazione di un simbolo con le lettere “CE” sul prodotto oggetto di marcatura. Essa è realizzata dal fabbricante di un prodotto regolamentato nell’Unione europea, il quale dichiara, per mezzo della dichiarazione di conformità o di prestazione per i prodotti da costruzione, che il prodotto è conforme ai requisiti di sicurezza previsti dalle direttive o dai regolamenti comunitari applicabili.<br><br> L’apposizione del marchio è prescritta per legge per poter commercializzare il prodotto nei paesi aderenti al Mercato europeo.<br><br> Apponendo il marchio CE su un prodotto, il produttore dichiara di rispettare tutti i requisiti previsti per ottenere il marchio stesso, assumendosi la responsabilità della sua commercializzazione entro lo spazio economico europeo. La marcatura CE è prevista anche per beni prodotti in Paesi terzi, poi commercializzati nell’area SEE e Turchia.<br><br> <b>ING GROUP</b> mette a disposizione del cliente la competenza e l’esperienza per curare tutto l’iter di regolarizzazione previsto dalla normativa comunitaria vigente.'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
